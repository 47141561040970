<template>
  <div class="main">
    <div class="row px-4 py-4 mb-3">
      <div
        v-for="folder in folders"
        :key="folder._id"
        class="contentContainer col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-4"
      >
        <div class="moreOptionsContainer d-flex justify-content-end">
          <svg
            @click="activeOptions = folder._id"
            class="bi"
            id="addBranch"
            fill="currentColor"
          >
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#three-dots-vertical"
            />
          </svg>
          <ul
            class="dropdown-menu menuOffset"
            @mouseleave="hideAllOptions"
            :class="{ show: activeOptions === folder._id }"
          >
            <li class="dropdown-item printOption" @click="sendFile(folder._id)">
              <svg fill="currentColor" class="bi">
                <use xlink:href="/img/icons/bootstrap-icons.svg#check" />
              </svg>
              Solicitar
            </li>
          </ul>
        </div>
        <div class="iconMainContainer d-flex flex-column">
          <svg
            v-if="folder.extension === 'PDF'"
            class="bi"
            id="addBranch"
            fill="currentColor"
          >
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#file-earmark-text-fill"
            />
          </svg>
          <svg
            v-else-if="folder.extension === 'MP4'"
            class="bi"
            id="addBranch"
            fill="currentColor"
          >
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#file-earmark-play-fill"
            />
          </svg>
          <svg v-else class="bi" id="addBranch" fill="currentColor">
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#file-earmark-image-fill"
            />
          </svg>
          <p class="text-center px-2">
            {{ folder.name }}
          </p>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.moreOptionsContainer {
  height: 30px;
  width: 94%;
  margin-left: 3%;
  position: relative;
}
.moreOptionsContainer svg {
  cursor: pointer;
  height: 80%;
  margin-top: 5%;
  width: 40px;
}
.addFile {
  height: 150px !important;
}
.dropdown-menu {
  width: fit-content;
}
.dropdown-menu li:hover {
  background-color: var(--main-bg);
  cursor: pointer;
  text-decoration: underline;
}
.dropdown-menu li svg {
  width: 16px;
  margin-right: 5px;
  height: 16px;
}
.editOption {
  color: gray;
}
.printOption {
  color: var(--bs-yellow);
}
.deleteOption {
  color: var(--bs-danger);
}
.menuOffset {
  top: 40px !important;
  left: 50% !important;
}
</style>

<script>
import Legal from "@/components/layout/Legal";
import Storage from "@/modules/storage";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: { Legal },
  data() {
    return {
      folders: [],
      activeOptions: false
    };
  },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Documentos / Archivo Inactivo / " +
                cityData.name +
                " / " +
                this.$route.params.year
            );
          });
        }
      });
    });
    const URL =
      store.baseURL +
      "/optima/fileInactive?where=parent=" +
      this.$route.params.folderId;
    axios
      .get(URL, {
        withCredentials: true
      })
      .then(response => {
        this.putFolder(response.data.body);
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  methods: {
    async putFolder(folders) {
      this.folders = folders;
    },
    sendFile(fileId) {
      this.activeOptions = false;

      const store = new Storage();
      const URL = store.baseURL + "/optima/fileInactiveSend/" + fileId;
      axios
        .get(URL, {
          withCredentials: true
        })
        .then(response => {
          Swal.fire({
            icon: "success",
            title: "Solicitud Enviada",
            text:
              "En los proximos días enviaremos el documento solcitado a su correo"
          });
        })
        .catch(function(error) {
          Swal.fire({
            icon: "error",
            title: "Error al Enviar Solicitud",
            text: "Por favor contáctenos para el envío del documento solicitado"
          });
        });
    },
    hideAllOptions() {
      this.activeOptions = false;
    }
  },
  name: "Document Inactive Folder"
};
</script>
